<template>
  <div>
    <CCard class="col-md-12">
      <CRow class="p-3">
       <h4 class="color-light ml-2 page_title"><span class="ul_text">DE</span>VELOPERS</h4>
      </CRow>
      <CCardBody>
        <CRow class="col-md-12">
          <p style="font-size: 14px">
            You can find all the information about how to use our API and its
            responsiblities in the developer documentation. You can
            <CHeaderNavLink to="/document/sdkdocument">
              explore it here
            </CHeaderNavLink> .
          </p>
          <h3>API Keys management</h3>

          <CRow class="col-md-12">
            <CCol class="col-md-2 col-12">
              <h5>Default</h5>
            </CCol>
            <CCol class="col-md-8 col-12">
              <h5
                style="
                  background-color: rgb(224, 237, 252);
                  padding: 2px;
                  border: groove;
                "
              >
                {{ masterKey }}
              </h5>
            </CCol>
            <CCol class="col-12 col-md-2">
              <CButton
                type="submit"
                variant=""
                color="info"
                @click="GenerateMasterKey()"
                >Generate</CButton
              >
            </CCol>
          </CRow>

          <CRow class="col-md-12 mt-3">
            <CCol class="col-md-2 col-12">
              <h5>Add API Key</h5>
              <!-- <span class="col-md-2"><CImg src="img/logo/plus_icon.png" title="add" style="cursor: pointer"></CImg></span> -->
            </CCol>
            <CCol class="col-md-4 col-12">
              <CInput
                size="sm"
                id="input-2"
                v-model="apikeyHolder"
                type="text"
                required
                placeholder="App Name"
                maxlength="26"
              ></CInput>
            </CCol>
            <CCol class="col-md-4 col-12">
              <CButton
                type="submit"
                variant=""
                color="info"
                @click="AddApikeyHolder()"
                >Generate</CButton
              >
            </CCol>
          </CRow>
          <CRow class="col-md-12">
            <CDataTable
            class="col-md-12"
              :fields="fields"
              :items="items"
              :dark="false"
              pagination
              sorter
              hover
              striped
              border
              small
              fixed
              light
            >
            <template #apikeyHolder="{item}">
              <td>
                <div class="res_text">{{item.apikeyHolder}}</div>
              </td>
            </template>
            <template #apikey="{item}">
              <td>
                <div class="res_text">{{item.apikey}}</div>
              </td>
            </template>
              <template #action="{ item }">
                <CButtonGroup class="p-2">
                  <CButton
                    class="m-1"
                    type="submit"
                    variant=""
                    color="info"
                    @click="UpdateApiKeyHolder(item.apikey, item.apikeyHolder)"
                    >Generate</CButton
                  >
                  <CButton
                    class="m-1"
                    type="submit"
                    variant=""
                    color="danger"
                    @click="DeleteApiKey(item.apikey, item.apikeyHolder)"
                    >Delete</CButton
                  >
                </CButtonGroup>
              </template>
            </CDataTable>
          </CRow>
        </CRow>

        <CRow class="col-md-10 mt-3">
          <p style="font-size: 12px">
            <span style="color: red">*</span>API keys allow you to access the
            fraudAvoid REST API and perform tasks such as creating and editing
            Campaigns, Offers and Traffic Sources programmatically. Please refer
            to our developer documentation to browse through all the details.
          </p>
          <P style="font-size: 12px"
            >API keys are used by external tools or third party developers to
            act on your behalf without having to use your username and password.
            Please keep them in a safe place and give them only to trusted
            partners.<b>
              Anybody with your API key could erase all of your campaigns.</b
            ></P
          >
        </CRow>
        <!-- </CCard> -->
      </CCardBody></CCard
    >
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import SettingService from "./../../services/settingControlService";
import HelperFunction from "./../../helper/helperFunction";
export default {
  data() {
    return {
      masterKey: "",
      apikeyHolder: "",
      fields: [
        { key: "apikeyHolder", label: "Appname", sortable: true },
        { key: "apikey", label: "Api Key", sortable: true },
        { key: "action", label: "Action", sortable: true },
      ],
      items: [],
    };
  },
  async mounted() {
    if (
      window.localStorage.getItem("user") &&
      window.localStorage.getItem("status") != "true"
    ) {
      this.$router.push("/");
    } else {
      try {
        this.getAllData();
      } catch (e) {
        console.log("connection error");
        // this.errorNotify("Error", "connection error", "danger");
      }
    }
  },
  computed: {
    validation() {
      //   if (this.mainObject.offername == "") {
      //     return false;
      //   }
    },
  },
  methods: {
    async getAllData() {
      try {
        let res = await SettingService.getdevapi();
        this.masterKey = res.message.masterKey;
        this.items = [];
        res.message.apiKeyHolders.map((item) => {
          this.items.push(item);
        });
      } catch (e) {
        console.log("connection error");
      }
    },
    async GenerateMasterKey() {
      try {
        let response = await SettingService.generatemasterkey();
        this.items = [];
        this.getAllData();
        Swal.fire({
          title: "Success!",
          text: "New ApiKey Updated",
          icon: "success",
          confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
        });
        // this.errorNotify("Success", "New Api Updated", "success");
      } catch (e) {
        console.log("connection error");
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    async UpdateApiKeyHolder(apikey, apikeyHolder) {
      try {
        let response = await SettingService.updateapikeyholder({
          apikey: apikey,
        });
        this.items = [];
        this.getAllData();
        Swal.fire({
          title: "Success!",
          text: "New ApiKey Updated for " + apikeyHolder,
          icon: "success",
          confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
        });
        // this.errorNotify("Success", "New Api Updated", "success");
      } catch (e) {
        console.log("connection error");
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    async AddApikeyHolder() {
      if(HelperFunction.validateName(this.apikeyHolder,25) && this.apikeyHolder != ''){
      let response = await SettingService.addapikeyholder({
          apikeyHolder: this.apikeyHolder,
        });
        this.apikeyHolder = "";
        this.items = [];
        this.getAllData();
        Swal.fire({
          title: "Success!",
          text: "New ApiKey Added",
          icon: "success",
          confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
        });
      }
        // this.errorNotify("Success", "New Api Updated", "success");
    },
    async DeleteApiKey(apikey, apikeyHolder) {
      if (confirm("Are you sure you want to delete this API key?")) {
        try {
          let response = await SettingService.deleteapikey({ apikey: apikey });
          Swal.fire({
            title: 'Deleted!',text: apikeyHolder + " Apikey has been deleted.",icon: 'success',confirmButtonText: 'Ok',
            position: 'top-end',showConfirmButton: false,timer: 1000
        });
          this.getAllData();
        } catch (e) {
          console.log("connection error");
          // Swal.fire("Error!", "Connection error.", "error");
        }
      }
    },
  },
};
</script>

<style scoped>
label {
  text-align: left;
  font-weight: 400;
  color: black;
  font-size: 12px;
}
</style>