import Swal from 'sweetalert2/dist/sweetalert2.js'
export default {
    validateName(name,maxLengthval) {
        var fullName = name
        // const regex = /^[a-zA-Z0-9 ]+$/; // Alphanumeric and space characters only
        // const regex = /^[a-zA-Z0-9 _-]+$/;
        const regex = /^(?!\s*$)[a-zA-Z0-9 _-]+$/;
        const maxLength = maxLengthval; // Maximum length of the full name field
        if (fullName.length >= maxLength) {
          Swal.fire({title: 'Error',text: "Name cannot exceed "+maxLength+" characters",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 2000})
          return false;
        }
        else if (!regex.test(fullName) && fullName != '') {
          name = ''
          Swal.fire({title: "Invalid",text: "Name can only contain alphanumeric characters and spaces",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 2000})
          return false;
        }
        return true;
    },
    validateNumber(number,min,max){
        if(number < min){
            Swal.fire({title: 'Error',text: "Can't add less than "+min,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 2000})
            return false;
        }
        else if(number > max){
            Swal.fire({title: 'Error',text: "Can't add more than "+max,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 2000})
            return false;
        }
        return true;
    },
    validateUrl(str) {
        if(str){
            var res = str.match(/^((http(s)?):\/\/)?([a-z0-9]+:[a-z0-9]+@)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
            if(res == null){
                return false;
            }else{
            return true;
            }
        }
      },
    validateDomains(domains) {
        // Split the comma-separated string into an array of domain names
        const domainList = domains.split(',');
        // Define a regular expression pattern to match valid domain names
        const domainPattern = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
        // Loop through each domain name in the array and check if it matches the pattern
        for (let i = 0; i < domainList.length; i++) {
          const domain = domainList[i].trim(); // Remove leading/trailing whitespace
          if (!domain.match(domainPattern)) {
            // If a domain name doesn't match the pattern, return false
            Swal.fire({title: 'Error!',text: "Invalid Domain.",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
            return false;
          }
        }
        // If all domain names match the pattern, return true
        return true;
    },
    validateCIDRIPs(input) {
        const ips = input.split(',');
        const cidrRegex = /^((\d{1,3}\.){3}\d{1,3}\/\d{1,2})|(([0-9a-fA-F]{1,4}:){1,7}[0-9a-fA-F]{1,4}\/\d{1,3})$/;
        for (let i = 0; i < ips.length; i++) {
          const ip = ips[i].trim();
          if (!cidrRegex.test(ip)) {
            return false;
          }
        }
        return true;
    },
    validateIPs(input) {
      const ips = input.split(',');
      const ipRegex = /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|[0-9a-fA-F]{0,4}(:[0-9a-fA-F]{0,4}){0,7})$/;
      for (let i = 0; i < ips.length; i++) {
        const ip = ips[i].trim();
        if (!ipRegex.test(ip)) {
          return false;
        }
      }
      return true;
    },
    validateemail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    },
    async encryptText(plaintext){
      const publicKeyPEM = `-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxU6qcjOwZLQmBocEJ1Tr\n73wBOlVvhCbftLeQhY6DQBZ9a8ieuqN/2hyGXDhnBjcpJtgtBW+CK6ULkSTHuNks\nG/usXspFRxqeeho/PA1+Z9R0KNo0fcL/12kYMyFhyx7N3jKcY+OlM4GUv6yk3Wm5\nN3oQn08RS6afy7A6tqC4EagUkSTiStjB0VHbhGp4tgcDDVhTASaGbw7CpnoIINfu\ni2HILz2tT4IWgpXzBjXZckcJMPHqBVsVHphyv3SLjzE9XKLL0gzAJxLSyJPLfszq\nOk9dB2zGofvdi+BEON66N5JzIGBSw3/W3km01M0FPZlqmeNMD7iFt59AQesP/5My\n5wIDAQAB\n-----END PUBLIC KEY-----\n`;
      const importPublicKey = async (publicKeyPEM) => {
            // Convert PEM string to ArrayBuffer
            const pemString = publicKeyPEM.replace(/-----BEGIN PUBLIC KEY-----/, '').replace(/-----END PUBLIC KEY-----/, '');
            const binaryString = atob(pemString);
            const arrayBuffer = new Uint8Array(binaryString.length);

            for (let i = 0; i < binaryString.length; i++) {
                arrayBuffer[i] = binaryString.charCodeAt(i);
            }
          // Import public key
            const importedKey = await window.crypto.subtle.importKey(
                'spki',
                arrayBuffer,
                { name: 'RSA-OAEP', hash: 'SHA-256' },
                true,
                ['encrypt']
            );

            return importedKey;
        };
        const importedPublicKey = await importPublicKey(publicKeyPEM);

        const encryptedBuffer = await window.crypto.subtle.encrypt(
                { name: 'RSA-OAEP' },
                importedPublicKey,
                new TextEncoder().encode(plaintext)
            );
        // Convert the encrypted ArrayBuffer to a base64-encoded string
        const encryptedString = btoa(String.fromCharCode.apply(null, new Uint8Array(encryptedBuffer)));
       return encryptedString;
    }
}